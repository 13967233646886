import React, { useContext } from 'react'

import Box from 'components/Box'
import Button from 'components/Button'
import Image from 'components/Image'
import Text from 'components/Text'
import Link from 'components/Link'
import { responsive } from 'components/ThemeProvider/theme';

import backrightleaf from 'components/NewsLayout/backrightleaf.png'
import frontrightleaf from 'components/NewsLayout/frontrightleaf.png'

import useResponsive from 'contexts/mediaQuery/useResponsive'

import applyContext from 'contexts/apply/context'

import ModuleList from './ModuleList'
import ruletitle from './ruletitle.png'
import leftui from './leftui.png'

const data = [
  {
    content:[
      {
        title: '本網站所取得您的個人資料，目的在於依個人資料保護法及其他相關法令之規定下，透過蒐集、處理及利用個人資料提供本您網站服務。您所填寫之個人資料，係供處理調解案件之用，除法令另有規定之外，本網站不會將您的個人資料運用在超出蒐集特定目的以外的用途，亦不會對第三者為揭露。',
      },
      {
        title: '為處理您的調解案件之聲請，須請您提供真實姓名、出生年月日、國民身分證統一編號、聯絡方式(含電話號碼、E-MAIL、地址)等個人資料，或其他得以直接或間接識別您個人之資料。',
      },
      {
        title: '您同意本網站以您所提供的個人資料確認您的身份、與您進行聯絡、提供您本網站相關服務及資訊，如您所填寫之個人資料錯誤或不實致受理調解委員會無法處理時，無法處理之後果由您自行承擔。',
      },
      {
        title: '您瞭解本網站蒐集、處理或利用您個人資料，依個人資料保護法第八、九條規定符合免為告知規定。',
      },
      {
        title: '您使用本網站服務，有下列情形之一者，本網站得終止其使用，並由您自負相關之法律責任：',
        items: [
          '有竊取、更改、破壞他人資訊情事者。',
          '有擅自複製他人資訊轉售、轉載情事者。',
          '未經對方同意，擅自寄發廣告信至其信箱者。',
          '任意散發垃圾信件者。',
          '蓄意破壞系統信箱或通信設備者。',
          '散播電腦病毒者。',
          '散播不實謠言或侮辱、誹謗性等妨害名譽等言論者。',
          '有盜用、冒用、偽造變造他人資訊聲請案件者。',
          '所為言論違背公共秩序或善良風俗者。',
          '擷取非經所有者正式開放或授權之資源。',
          '其他有危害通信或違反法令之情事者。'
        ]
      },
      {
        title: '您瞭解此一同意符合個人資料保護法及其他相關法令之規定，具有同意本網站蒐集、處理及利用您的個人資料之效力，並同意遵守各條款之內容。'
      },
    ],
  },
]

const Popup = ({ id, ...props }) => {
  const { isMobile } = useResponsive()
  const { applyByKey } = useContext(applyContext)
  return (
    <Box {...props}>
      <Box width="10.625em" mx="auto" mb="1em">
        <Image src={ruletitle} alt="個資法規" />
      </Box>
      <Box position="relative">
        <Box
          py={responsive('1em', '6.5em')}
          borderTop="5px solid"
          borderBottom="5px solid"
          borderColor="lightPurple"
        >
          <Text color="darkPurple" mb={responsive('1em', '1.625em')} fontSize="1.125em">當您進入本網站進行線上聲請調解時，請您詳閱以下內容並同意後，始進入下一步聲請書填寫：</Text>
          <ModuleList px={responsive('1.25em', 0)} data={data} />
          {!isMobile && (
            <Box position="absolute" bottom="-7%" right="98%" width="7.25em">
              <Image src={leftui} alt="裝飾的樹葉圖樣" />
            </Box>
          )}
        </Box>
        {isMobile ? (
          <>
            <Box position="absolute" mx="auto" bottom="0%" left="50%" width="6.25em" transform="translateY(70%)">
              <Image src={backrightleaf} alt="裝飾的樹葉圖樣" />
            </Box>
            <Box position="absolute" mx="auto" bottom="0" left="40%" width="4.5em" transform="translateY(105%)">
              {id && <Image src={applyByKey[id].src} alt="文件圖樣" />}
            </Box>
            <Box position="absolute" mx="auto" bottom="0%" left="50%" transform="translateY(150%)" width="4.75em">
              <Image src={frontrightleaf} alt="裝飾的樹葉圖樣" />
            </Box>
          </>
        ) : (
          <>
            <Box position="absolute" mx="auto" bottom="-7%" left="100%" width="8.75em">
              <Image src={backrightleaf} alt="裝飾的樹葉圖樣" />
            </Box>
            <Box position="absolute" mx="auto" bottom="0" right="0" width="7.125em" transform="translate(33%, 50%)">
              {id && <Image src={applyByKey[id].src} alt="文件圖樣" />}
            </Box>
            <Box position="absolute" mx="auto" bottom="-10%" left="100%" width="6.5em">
              <Image src={frontrightleaf} alt="裝飾的樹葉圖樣" />
            </Box>
          </>
        )}
      </Box>
      <Box textAlign="center" mt={responsive('9.5em', 0)}>
        <Box mt="2.625em">
          <Button
            as={Link}
            to={`/apply/${id}`}
            fontSize={responsive('1.125em', '1.625em')}
          >已詳細審閱上述各項內容並同意
          </Button>
        </Box>
        <Box pt="1.5em">
          <Box
            as={(p) => <Link color="text" {...p} />}
            to="/"
            fontSize={responsive('1em', '1.25em')}
            pb="0.25em"
            borderBottom="1px solid"
            borderColor="orange"
          >不同意上述各項內容並離開</Box>
        </Box>
      </Box>
    </Box>
  )
}

export default Popup
