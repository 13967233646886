import React from 'react'
// import styled from 'styled-components'

import ClaimProvider from 'contexts/apply/Provider'
import Page from 'containers/ApplyPage'
// import Text from 'components/Text'
// import AddNotice from '../containers/AddNotice'

// const Table = styled.table`
//   font-size: 1.125rem;
//   margin-top: 1rem;
//   width: 100%;
//   td {
//     padding: 0.25em 0.125em;
//     border-top: 1px solid #e6e6e6;
//     white-space: normal;
//   }
// `

// const EmgNotice = () => (
//   <div>
//     <Text as="h2" fontSize="1.5em">
//       公告
//     </Text>
//     <Text>
//       本系統因故障維修中，若您多次線上聲請仍無法成功時，請逕洽案件所轄該區調解委員會詢問受理事宜。
//     </Text>
//     <Text mt="0.5rem" fontSize="1rem">
//       **新北市各區公所調解會聯絡電話
//     </Text>
//     <Table>
//       <tbody>
//         <tr>
//           <td>板橋</td>
//           <td>2968-9901</td>
//           <td>淡水</td>
//           <td>2622-1020#4000</td>
//           <td>萬里</td>
//           <td>2492-2064#315</td>
//         </tr>
//         <tr>
//           <td>中和</td>
//           <td>2240-2678</td>
//           <td>三峽</td>
//           <td>2674-2726</td>
//           <td>金山</td>
//           <td>2498-5965#111</td>
//         </tr>
//         <tr>
//           <td>新莊</td>
//           <td>2992-9891#243</td>
//           <td>林口</td>
//           <td>2603-3111#406</td>
//           <td>貢寮</td>
//           <td>2494-1601#206</td>
//         </tr>
//         <tr>
//           <td>三重</td>
//           <td>2988-5782</td>
//           <td>鶯歌</td>
//           <td>2678-0202#611</td>
//           <td>石門</td>
//           <td>2638-1721#306</td>
//         </tr>
//         <tr>
//           <td>新店</td>
//           <td>2918-0428</td>
//           <td>五股</td>
//           <td>2292-0563</td>
//           <td>雙溪</td>
//           <td>2493-1111#110</td>
//         </tr>
//         <tr>
//           <td>土城</td>
//           <td>2273-2007</td>
//           <td>泰山</td>
//           <td>2909-9975</td>
//           <td>石碇</td>
//           <td>2663-1080#257</td>
//         </tr>
//         <tr>
//           <td>永和</td>
//           <td>2231-8050</td>
//           <td>瑞芳</td>
//           <td>2497-2250#1160</td>
//           <td>坪林</td>
//           <td>2665-7251#208</td>
//         </tr>
//         <tr>
//           <td>蘆洲</td>
//           <td>2281-1484#582</td>
//           <td>八里</td>
//           <td>2610-2621#117</td>
//           <td>烏來</td>
//           <td>2661-6442#254</td>
//         </tr>
//         <tr>
//           <td>汐止</td>
//           <td>2642-7222</td>
//           <td>深坑</td>
//           <td>2662-3116#207</td>
//           <td>平溪</td>
//           <td>2495-1510#163</td>
//         </tr>
//         <tr>
//           <td>樹林</td>
//           <td>2681-2106#1701</td>
//           <td>三芝</td>
//           <td>2636-2111#268</td>
//           <td></td>
//           <td></td>
//         </tr>
//       </tbody>
//     </Table>
//   </div>
// )

export default () => {
  return (
    <ClaimProvider>
      <Page />
    </ClaimProvider>
  )
}
