import React from 'react'
import styled from 'styled-components'
import themeGet from '@styled-system/theme-get';

import Box from 'components/Box'
import { responsive } from 'components/ThemeProvider/theme';

const Styledli = styled.li`
  position: relative;
  font-weight: bold;
  list-style-type: none;
  &::before {
    font-weight: normal;
    font-family: Gidole;
    line-height: 1;
    font-size: 1.875em;
    color: ${themeGet('colors.blue')};
    content: '${props => '0' + (props.id + 1)}';
    position: absolute;
    width: 1.5em;
    right: 100%;
    top: 0;
  }
`

const Itemol = styled.ol`
  list-style-type: cjk-ideographic;
`

const ModuleList = ({ data, ...props }) => data.map(({ notice, content }, index) => (
  <Box key={index} {...props}>
    <Box mt={index && '3rem'} color="darkPurple" fontWeight="bold" fontSize="1.125em">
      {notice}
    </Box>
    <Box ml="2.5em">
      {content.map(({ title, items }, k) => (
        <Box mt={responsive('1.25em', '1.625em')} key={k}>
          <Styledli id={k}>{title}</Styledli>
          <Itemol>
            {items && items.map((item, i) => (
              <Box as="li" lineHeight="1.75" key={i}>{item}</Box>
            ))}
          </Itemol>
        </Box>
      ))}
    </Box>
  </Box>
))

export default ModuleList
